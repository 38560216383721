import React from 'react';

import { Navigation } from '../utilities/components/navigation/component';
import { Timeline } from '../features/history/timeline/component';
import { Implementations } from 'features/history/implemantations/component';
import { Footer } from '../utilities/components/footer/component';
import { MESSENGER_APP_ID, MESSENGER_PAGE_ID } from 'utilities/consts';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import SEO from 'utilities/components/seo';
import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';

import { routes } from 'utilities/routes';

import 'features/history/view.scss';
import { useSeoTexts } from 'utilities/hooks/useSeoTexts';
import { SeoDecription } from 'features/index/seo-description/view';

const History = () => {
    const breadcrumbs = [routes.home, routes.historia];

    const setCanonicalLink = () => {
        let relCanonical = '';
        if (typeof window !== 'undefined') {
            relCanonical = `${window.location.origin}${window.location.pathname}`;
        }
        return relCanonical;
    };
    const { metaTitle, metaDescription, description } = useSeoTexts('Historia');

    return (
        <main className="history">
            <SEO
                title={ metaTitle || 'Barański Drzwi - Historia - Poznaj historię naszej marki' }
                description={
                    metaDescription
                    || 'Jesteśmy przedsiębiorstwem rodzinnym działającym na rynku od ponad 30 lat. Dowiedz się więcej o historii marki i naszych nowych realizacjach.'
                }
                image="https://www.baranskidrzwi.pl/static/1982-cc6beb0a2e8524e05dfaf5040461b77a.png"
                alt="Historia"
                canonicalLink={ setCanonicalLink() }
            />
            <Navigation />
            <Breadcrumbs breadcrumbs={ breadcrumbs } />
            <Timeline />
            <Implementations />
            <SeoDecription seoDescription={ description } />
            <Footer />
            <MessengerCustomerChat pageId={ MESSENGER_PAGE_ID } appId={ MESSENGER_APP_ID } />
        </main>
    );
};

export default History;
