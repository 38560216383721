import React from 'react';
import PropTypes from 'prop-types';

import { Img } from 'utilities/components/img/component';

import './view.scss';

export const TimelineView = ({ events }) => (
    <section className="timeline">
        {
            events.map((currentValue, index) => (
                <div className="timeline__event" key={ index }>
                    <Img className="event__image" src={ currentValue.image } alt={ currentValue.headline } />
                    <div className="event__details">
                        {
                            (index === 7)
                                ? (<h1 className="event__headline">{ currentValue.headline }</h1>)
                                : (<h4 className="event__headline">{ currentValue.headline }</h4>)
                        }
                        <p className="event__description">{ currentValue.description }</p>
                    </div>
                    <p className="event__date">{ currentValue.date }</p>
                </div>
            ))
        }
    </section>
);

TimelineView.propTypes = {
    events: PropTypes.instanceOf(Array).isRequired
};