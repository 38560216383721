import React from 'react';

import { TimelineView } from './view';

import image1982 from 'assets/images/history/1982.png';
import image80s from 'assets/images/history/80s.png';
import image1990 from 'assets/images/history/1990.png';
import image1995 from 'assets/images/history/1995.jpg';
import image2000 from 'assets/images/history/2000.jpg';
import image2012 from 'assets/images/history/2012.png';
import image2014 from 'assets/images/history/2014.jpg';
import image2019 from 'assets/images/history/2019.png';

export const Timeline = () => {
    const events = [
        {
            image: image1982,
            headline: 'Pewnego razu...',
            description: (
                <>
                    W 1982 roku Ignacy odwiedził pewien tartak, przed którym ustawiały się kolejki chętnych do przecierania bali. Klienci narzekali na tempo pracy, przerwy, awarie, zarządcę tartaku. Wtedy w głowie przedsiębiorcy zaświtała myśl: przecież mogę to zorganizować znacznie lepiej i stworzyć w ten sposób dochodową działalność!
                    <br />
                    <br />
                    Tak właśnie się stało. Wykorzystując część kombajnu zbożowego oraz skrzynię biegów z siewnika, Ignacy skonstruował tartak, który przez następne lata obsługiwał całą okolicę.
                    <br />
                    <br />
                    Przedsięwzięciem stolarskim oraz obróbką drewna zaczął się interesować także syn Ignacego – Zbigniew. Miał wówczas zaledwie osiem lat, ale już wtedy w jego głowie powoli zaczynała kiełkować wielka wizja…
                </>
            ),
            date: '1982'
        }, {
            image: image80s,
            headline: 'Pierwsze produkty wykończeniowe',
            description: (
                <>
                    W drugiej połowie lat 80. firma poszerzyła swoją działalność i zaczęła oferować pierwsze produkty wykończeniowe: szafki oraz boazerie.
                    <br />
                    <br />
                    Na tym etapie poza tartakiem działalność prowadzona była jeszcze w przydomowych garażach Ignacego i Zbigniewa Barańskich w Jakubowie Kisielickim. Stopniowo przedsiębiorstwo zaczęło wprowadzać do sprzedaży kolejne produkty, w tym szafy wnękowe oraz drzwi.
                    <br />
                    <br />
                    Rozwój firmy był nieunikniony, bo Ignacy, a w ślad za nim Zbigniew, zakochali się w drewnie po uszy.
                </>
            ),
            date: 'lata 80'
        }, {
            image: image1990,
            headline: 'Przyspieszamy i poprawiamy jakość!',
            description: (
                <>
                    W 1990 r. Zbigniew Barański zaczął wykorzystywać wiedzę wyniesioną ze szkoły i doświadczenie zdobyte podczas pracy w zakładach stolarskich w działalności swojej rodzinnej firmy.
                    <br />
                    <br />
                    Dzięki jego zaangażowaniu jakość produktów w przedsiębiorstwie Barańskich znacząco wzrosła. Poszerzyła się również oferta, m.in. o schody, okna i podłogi. W końcu firma zaczęła wykańczać domy pod klucz.
                    <br />
                    <br />
                    Renoma marki rosła. Zlecenia zaczęły spływać spoza rodzimego regionu; firma zdobywała ogólnopolski zasięg.
                </>
            ),
            date: '1990'
        }, {
            image: image1995,
            headline: 'Pierwsza profesjonalna hala produkcyjna',
            description: (
                <>
                    W 1995 r. w miejscowości Babięty Wielkie powstała pierwsza profesjonalna hala produkcyjna firmy Barańskich. Tam też przeniesiono działalność przedsiębiorstwa. Siedziba firmy do dziś znajduje się w tym miejscu.
                    <br />
                    <br />
                    3 stycznia 1997 r. firma przyjęła nazwę Markop i pod tą marką rozpoczęła sprzedaż swoich produktów. Przedsiębiorstwo postawiło na specjalizację. Do 1998 r. konsekwentnie ograniczało swoją ofertę, skupiając się na produkcji schodów i surowych drzwi sosnowych.
                </>
            ),
            date: '1995'
        }, {
            image: image2000,
            headline: 'Rozwijamy się i rozszerzamy działalność',
            description: (
                <>
                    W 2000 r. firma wygrała pierwszy przetarg dla międzynarodowego koncernu Alstom zajmującego się infrastrukturą energetyczną i transportową.  Barański Drzwi (wtedy jeszcze Markop) zaczął tworzyć drewniane opakowania do turbin oraz stalowych elementów wielkogabarytowych.
                    <br />
                    <br />
                    Dzięki zdobytemu doświadczeniu oraz środkom pozyskanym w efekcie współpracy z międzynarodowym partnerem nastąpił okres dynamicznych inwestycji w rozwój możliwości produkcyjnych drzwi.
                    <br />
                    <br />
                    W 2003 r. powstała pierwsza profesjonalna lakiernia drzwi wewnętrznych, która została następnie powiększona o kolejne 400 m
                    <sup>2 </sup>
                    w 2006 r. Tym samym firma zaczęła oferować drzwi malowane.
                    <br />
                    <br />
                    W 2005 r. wybudowano nową halę produkcyjną o powierzchni 1 300 m
                    <sup>2</sup>
                    , która do 2009 roku została wyposażona w nowoczesny i zaawansowany park maszynowy.
                </>
            ),
            date: '2000'
        }, {
            image: image2012,
            headline: 'Nowy park maszynowy i rebranding marki',
            description: (
                <>
                    2012 r. był kolejnym kamieniem milowym na mapie rozwoju firmy. Zrezygnowano już całkowicie z innej działalności poza produkcją drzwi wewnętrznych, drewnianych oraz schodów.
                    <br />
                    <br />
                    W odpowiedzi na zapytania i potrzeby partnerów handlowych zdecydowano się także na otwarcie produkcji drzwi zewnętrznych drewnianych. W związku z tym dokonano kolejnych inwestycji w park maszynowy oraz rozbudowano dotychczasowe hale produkcyjne.
                    <br />
                    <br />
                    Podjęto decyzję o zmianie nazwy przedsiębiorstwa. Choć produkty firmy w tym czasie oficjalnie były sygnowane marką Markop, to jednak bardziej rozpoznawalnym elementem było nazwisko właścicieli, którzy reprezentowali firmę w kontaktach z klientami. Często to właśnie Ignacy i Zbigniew dokonywali pomiarów, a następnie montowali drzwi u klientów. Rebranding był zatem naturalną konsekwencją faktycznego stanu rzeczy: powstał Barański Producent Drzwi i Schodów.
                </>
            ),
            date: '2012'
        }, {
            image: image2014,
            headline: 'Rozbudowujemy moc produkcyjną. Nowe produkty',
            description: (
                <>
                    Firma coraz bardziej się profesjonalizowała. W 2014 r., idąc z duchem czasu, przedsiębiorstwo postanowiło zakupić nową automatyczną linię lakierniczą. Zaawansowane roboty malujące przyspieszyły oraz ułatwiły proces malowania, znacząco podnosząc jakość produktów.
                    <br />
                    <br />
                    W 2016 r. pojawiły się koncepcje stworzenia nowego produktu opartego o płytę MDF oraz folie syntetyczne, dziś znanego pod marką Barański Optimo.
                    <br />
                    <br />
                    W 2018 r. ruszyła natomiast budowa nowej hali o powierzchni 4 200 m
                    <sup>2</sup>
                    , która miała ów pomysł urzeczywistnić.
                    <br />
                    <br />
                    Również w 2018 opracowano nowy produkt drzwi klatkowych oraz technicznych drewnianych znanych obecnie pod brandem Barański Smart.
                    <br />
                    <br />
                    Firma całkowicie zrezygnowała z produkcji schodów i skupiła się w 100% na rozwijaniu produkcji drzwi.
                </>
            ),
            date: '2014'
        }, {
            image: image2019,
            headline: '2019 - Rok wielkich zmian',
            description: (
                <>
                    Zdecydowano się na kolejny rebranding: główny brand został uproszczony do „Barański Drzwi” i zyskał nowoczesny wygląd. W związku z poszerzeniem oferty wyróżniono submarki dla trzech kluczowych grup produktowych: Barański Premium (najwyższej jakości drewniane drzwi zewnętrzne i wewnętrzne), Barański Smart (drzwi klatkowe i techniczne) oraz Barański Optimo (wewnętrzne drzwi mieszkaniowe dostępne dla szerszego grona odbiorców).
                    <br />
                    <br />
                    Wybudowano elektrownię słoneczną mogącą w pełni zaspokoić zapotrzebowanie energetyczne firmy.
                    <br />
                    <br />
                    Na koniec 2019 roku firma była już dużym, prężnie działającym przedsiębiorstwem dysponującym ponad 200-osobową kadrą i przeszło 10 000 m
                    <sup>2 </sup>
                    powierzchni produkcyjnej i magazynowej.
                </>
            ),
            date: '2019'
        }
    ];

    return (<TimelineView events={ events } />);
};